var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"practice-area-step"},[_c('h1',{staticClass:"step-header"},[_vm._v("担当している"+_vm._s(_vm.practiceLabel)+"を選択する")]),_vm._v(" "),_c('p',{staticClass:"step-paragraph"},[_vm._v("\n    以下のリストから担当されている"+_vm._s(_vm.practiceLabel)+"を選択いただき、その経験年数をご回答ください。"+_vm._s(_vm.practiceLabel)+"は複数選択できます。適切な"+_vm._s(_vm.practiceLabel)+"がリストにない場合は「その他」をお選びください。\n  ")]),_vm._v(" "),_c('ul',{staticClass:"no-bullet-list"},[_vm._l((_vm.practices),function(practice,index){return _c('li',{key:index,staticClass:"item"},[(_vm.isExperienceRequired(practice.practiceId))?[_c('v-select',{attrs:{"value":practice.practiceId,"items":[
            _vm.getCurrentSelectedPracticeOption(practice.practiceId),
            ..._vm.filterSelectablePracticeOptions(_vm.practices),
          ],"item-text":"name","item-value":"id","filled":"","dense":""},on:{"input":function($event){return _vm.updatePracticeIdHandler(index, $event)}}}),_vm._v(" "),_c('v-select',{attrs:{"value":practice.experienceId,"items":_vm.experienceOptions,"item-text":"name","item-value":"id","filled":"","dense":""},on:{"input":function($event){return _vm.updateExperienceIdHandler(index, $event)}}})]:[_c('v-select',{attrs:{"value":practice.practiceId,"items":[
            _vm.getCurrentSelectedPracticeOption(practice.practiceId),
            ..._vm.filterSelectablePracticeOptions(_vm.practices),
          ],"item-text":"name","item-value":"id","filled":"","dense":""},on:{"input":function($event){return _vm.updatePracticeIdHandler(index, $event)}}})],_vm._v(" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deletePractice(index)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)],2)}),_vm._v(" "),_c('li',{staticClass:"item"},[_c('v-btn',{attrs:{"depressed":""},on:{"click":_vm.addPractice}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.practiceLabel)+"を追加する")],1)],1)],2),_vm._v(" "),_c('div',{staticClass:"button-area"},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.valid},on:{"click":function($event){return _vm.$emit('next')}}},[_vm._v(_vm._s(_vm.nextLabel))]),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v("戻る")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }