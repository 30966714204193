import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import { practiceValidator } from '@/utils/profileUtils';
import { useRepositories } from '@/plugins/repositories';
export default defineComponent({
  props: {
    practices: {
      type: Array,
      required: true
    },
    nextLabel: {
      type: String,
      required: true
    },
    isLawOffice: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:practices', 'update:practices-summary', 'next', 'back'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var blankPracticeOption = {
      name: '選択してください',
      id: -1,
      isExperienceRequired: false
    };
    var blankExperienceOption = {
      name: '選択してください',
      id: -1
    };
    var repositories = useRepositories();
    var valid = ref(false);
    var practiceOptions = ref([]);
    var experienceOptions = ref([]);
    repositories.users.listPracticesForUserProfile().then(function (res) {
      practiceOptions.value = [_objectSpread({}, blankPracticeOption)].concat(_toConsumableArray(res.practices));
      experienceOptions.value = [_objectSpread({}, blankExperienceOption)].concat(_toConsumableArray(res.experiences));
    });
    var getCurrentSelectedPracticeOption = function getCurrentSelectedPracticeOption(currentSelectedPracticeId) {
      return practiceOptions.value.find(function (option) {
        return option.id === currentSelectedPracticeId;
      });
    };
    var filterSelectablePracticeOptions = function filterSelectablePracticeOptions(selectedPractices) {
      var BLANK_PRACTICE_ID = -1;
      var unselectablePractices = [].concat(_toConsumableArray(selectedPractices), [{
        practiceId: BLANK_PRACTICE_ID
      }]);
      var unselectablePracticeIds = unselectablePractices.map(function (practice) {
        return practice.practiceId;
      });
      return practiceOptions.value.filter(function (option) {
        return !unselectablePracticeIds.includes(option.id);
      });
    };
    var generateSummary = function generateSummary(practices) {
      return practices.map(function (practice) {
        var practiceOption = practiceOptions.value.find(function (option) {
          return option.id === practice.practiceId;
        });
        if (!practiceOption) return '';
        var experienceOption = experienceOptions.value.find(function (option) {
          return option.id === practice.experienceId;
        });
        return "".concat(practiceOption.name).concat((experienceOption === null || experienceOption === void 0 ? void 0 : experienceOption.name) || '');
      }).join('、');
    };
    var validateAndUpdate = function validateAndUpdate(practices) {
      valid.value = practiceValidator(practices);
      emit('update:practices-summary', generateSummary(practices));
      emit('update:practices', practices);
    };
    var addPractice = function addPractice() {
      var practices = [].concat(_toConsumableArray(props.practices), [{
        practiceId: -1
      }]);
      validateAndUpdate(practices);
    };
    var deletePractice = function deletePractice(index) {
      var practices = props.practices.filter(function (_, _index) {
        return index !== _index;
      });
      validateAndUpdate(practices);
    };
    var updatePracticeIdHandler = function updatePracticeIdHandler(targetIndex, practiceId) {
      var practices = props.practices.map(function (practice, index) {
        if (index !== targetIndex) return practice;
        var practiceOption = practiceOptions.value.find(function (option) {
          return option.id === practiceId;
        }) || blankPracticeOption;
        if (practiceOption.isExperienceRequired) {
          if (practice.experienceId === -1 || practice.experienceId === undefined) {
            return {
              withExperience: true,
              practiceId: practiceOption.id,
              experienceId: blankExperienceOption.id
            };
          }
          return _objectSpread(_objectSpread({}, practice), {}, {
            practiceId: practiceOption.id
          });
        }
        return {
          withExperience: false,
          practiceId: practiceOption.id
        };
      });
      validateAndUpdate(practices);
    };
    var updateExperienceIdHandler = function updateExperienceIdHandler(targetIndex, experienceId) {
      var practices = props.practices.map(function (practice, index) {
        if (index !== targetIndex || practice.experienceId === undefined) return practice;
        var experienceOption = experienceOptions.value.find(function (option) {
          return option.id === experienceId;
        }) || blankPracticeOption;
        return _objectSpread(_objectSpread({}, practice), {}, {
          experienceId: experienceOption.id
        });
      });
      validateAndUpdate(practices);
    };
    var isExperienceRequired = function isExperienceRequired(id) {
      var _practiceOptions$valu;
      return ((_practiceOptions$valu = practiceOptions.value.find(function (option) {
        return option.id === id;
      })) === null || _practiceOptions$valu === void 0 ? void 0 : _practiceOptions$valu.isExperienceRequired) || false;
    };
    onMounted(function () {
      return validateAndUpdate(props.practices);
    });
    var practiceLabel = computed(function () {
      return props.isLawOffice ? 'プラクティス' : '主なリサーチ分野';
    });
    return {
      practiceOptions: practiceOptions,
      experienceOptions: experienceOptions,
      getCurrentSelectedPracticeOption: getCurrentSelectedPracticeOption,
      filterSelectablePracticeOptions: filterSelectablePracticeOptions,
      addPractice: addPractice,
      deletePractice: deletePractice,
      updatePracticeIdHandler: updatePracticeIdHandler,
      updateExperienceIdHandler: updateExperienceIdHandler,
      isExperienceRequired: isExperienceRequired,
      valid: valid,
      practiceLabel: practiceLabel
    };
  }
});