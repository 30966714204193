import { PutUserProfileParamsBodyPracticesInner } from 'wklr-backend-sdk/models';
import type { InputValidationRules } from 'vuetify';

export const apprenticePeriodRules: InputValidationRules = [
  (input) => {
    if (!input) return true;
    return Number.isInteger(Number(input)) || '半角数字の整数で入力ください';
  },
  (input) => {
    if (!input) return true;
    const num = Number(input);
    return num < 1 || 100 < num || !Number.isInteger(num) ? '1〜100の範囲で入力ください' : true;
  },
];

export const practiceValidator = (practices: PutUserProfileParamsBodyPracticesInner[]): boolean => {
  if (practices.length < 1) return false;
  return practices.every((practice) => practice.practiceId !== -1 && practice.experienceId !== -1);
};
