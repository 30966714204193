import { useToast } from '@/plugins/vueToast';

export const useApiErrorToast = () => {
  const toast = useToast();
  const showRetriableErrorToast = (message: string) => {
    toast.error(
      `${message}。少し時間を空けてから再操作をお願いいたします<br>それでも改善しない場合はヘッダーの？アイコンからユーザーサポートにご連絡ください`,
    );
  };
  return { showRetriableErrorToast };
};
