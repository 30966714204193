import { apprenticePeriodRules } from '@/utils/profileUtils';
export default defineComponent({
  props: {
    apprenticePeriod: {
      type: String,
      default: null
    },
    nextLabel: {
      type: String,
      required: true
    },
    isLawOffice: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:apprenticePeriod', 'next', 'back'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var valid = ref(true);
    var updateHandler = function updateHandler(value) {
      emit('update:apprenticePeriod', value);
    };
    return {
      apprenticePeriodRules: apprenticePeriodRules,
      updateHandler: updateHandler,
      valid: valid
    };
  }
});